import {
  SelectedVariants,
  ServicePaymentDto,
  PaymentType,
} from '@wix/bookings-uou-types';
import {
  BookingLineItem,
  PriceInfo,
} from '@wix/ambassador-bookings-v2-price-info/types';
import type {
  ServiceChoice,
  ServiceOption,
  ServiceVariant,
} from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import { ServiceOptionType } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import { PaymentDetails, CustomOption } from '../../types/dynamicPrice';
import { PaymentDtoMapper, PriceUtils } from '@wix/bookings-uou-mappers';
import { PriceChoice } from '../../components/BookingsForm/Widget/PriceOptionDropdown/PriceOptionDropdown';

export const mapVariantsToLineItems = ({
  serviceId,
  resourceId,
  selectedVariants,
}: {
  serviceId: string;
  resourceId: string;
  selectedVariants: SelectedVariants[];
}): BookingLineItem[] => {
  const bookingLineItems: BookingLineItem[] = selectedVariants.map(
    ({ numberOfParticipants, choices }) => {
      const staffMemberId = choices?.find(
        (choice: ServiceChoice) => choice?.staffMemberId,
      )?.staffMemberId;
      const customChoices = choices?.reduce(
        (totalChoices: ServiceChoice[], choice: ServiceChoice) => {
          return choice?.custom ? [...totalChoices, choice] : totalChoices;
        },
        [],
      );
      return {
        serviceId,
        resourceId: staffMemberId || resourceId,
        numberOfParticipants,
        choices: customChoices,
      };
    },
  );
  return bookingLineItems;
};

export const mapPriceInfoToPaymentDetails = ({
  priceInfo,
  dynamicPriceOptions,
  locale,
}: {
  priceInfo: PriceInfo;
  dynamicPriceOptions: ServiceOption[];
  locale: string;
}): PaymentDetails[] => {
  let paymentDetails: PaymentDetails[] = [];
  if (isCustomTypeOption(dynamicPriceOptions)) {
    paymentDetails =
      priceInfo.bookingLineItems?.map((bookingLineItem: BookingLineItem) => {
        return {
          numberOfParticipants: bookingLineItem.numberOfParticipants || 1,
          choice: bookingLineItem.choices?.[0]?.custom!,
          price: {
            value: String(bookingLineItem.pricePerParticipant),
            currency: priceInfo.currency!,
            formattedValue: PriceUtils.getFormattedCurrency({
              price: bookingLineItem.pricePerParticipant!,
              currency: priceInfo.currency!,
              locale,
            }),
          },
        };
      }) || [];
  }
  return paymentDetails;
};

function isCustomTypeOption(dynamicPriceOptions: ServiceOption[]) {
  return (
    dynamicPriceOptions.length === 1 &&
    dynamicPriceOptions?.[0].type === ServiceOptionType.CUSTOM
  );
}

export function isCustomVariant(variant: SelectedVariants): boolean {
  return !!variant.choices?.find((choice) => choice.custom);
}

function serviceChoiceToDropdownOptionMapper(choice: string): PriceChoice {
  return {
    title: choice,
    choiceId: choice,
  };
}

function mapSelectedVariantsToCustomOptions(
  selectedVariants: SelectedVariants[],
): CustomOption[] {
  const prepopulatedCustomOptions: CustomOption[] = [];
  selectedVariants.forEach((variant) => {
    if (isCustomVariant(variant)) {
      const optionId = variant.choices?.[0]?.optionId!;
      let selectedVariant = prepopulatedCustomOptions.find(
        (option) => option.optionId === optionId,
      );
      if (!selectedVariant) {
        selectedVariant = { optionId, options: [], name: '' };
        prepopulatedCustomOptions.push(selectedVariant);
      }
      const custom: string = variant.choices?.[0]?.custom!;
      selectedVariant.options.push({
        ...serviceChoiceToDropdownOptionMapper(custom),
        numberOfParticipantsInitialValue: variant?.numberOfParticipants,
      });
    }
  });
  return prepopulatedCustomOptions;
}

function customServiceOptionsToCustomOptions(
  serviceOptions: ServiceOption[],
  customOptions: CustomOption[],
): CustomOption[] {
  serviceOptions
    .filter(
      (serviceOption: ServiceOption) =>
        serviceOption.type === ServiceOptionType.CUSTOM,
    )
    .forEach((serviceOption: ServiceOption) => {
      const dropdownChoices = customOptions.find(
        (options) => options.optionId === serviceOption.id,
      );
      if (dropdownChoices) {
        dropdownChoices.name = serviceOption.customData?.name!;
        serviceOption.customData?.choices?.forEach((choice: string) => {
          if (
            !dropdownChoices.options.find(
              (option: PriceChoice) => option.choiceId === choice,
            )
          ) {
            dropdownChoices.options.push(
              serviceChoiceToDropdownOptionMapper(choice),
            );
          }
        });
      } else {
        customOptions.push({
          optionId: serviceOption.id!,
          name: serviceOption.customData?.name!,
          options:
            serviceOption.customData?.choices?.map(
              serviceChoiceToDropdownOptionMapper,
            ) || [],
        });
      }
    });
  return customOptions;
}

export function selectedVariantsAndServiceOptionsToCustomOptions({
  selectedVariants = [],
  serviceOptions = [],
  serviceVariants = [],
  dateRegionalSettingsLocale = 'en-US',
}: {
  selectedVariants?: SelectedVariants[];
  serviceOptions?: ServiceOption[];
  serviceVariants?: ServiceVariant[];
  dateRegionalSettingsLocale?: string;
}): CustomOption[] {
  const mappedSelectedVariantsToCustomOptions: CustomOption[] = mapSelectedVariantsToCustomOptions(
    selectedVariants,
  );

  const customOptions = customServiceOptionsToCustomOptions(
    serviceOptions,
    mappedSelectedVariantsToCustomOptions,
  );

  return addPriceToCustomOptions(
    serviceVariants,
    customOptions,
    dateRegionalSettingsLocale,
  );
}

export function addPriceToCustomOptions(
  serviceVariants: ServiceVariant[],
  customOptions: CustomOption[],
  dateRegionalSettingsLocale: string,
): CustomOption[] {
  const paymentDtoMapper = new PaymentDtoMapper(dateRegionalSettingsLocale);
  serviceVariants
    .filter(
      (variant) =>
        variant.choices?.length === 1 &&
        isCustomVariant(variant) &&
        variant.price?.value,
    )
    .forEach((variant) => {
      let choiceIndex = -1;
      const currentOption = customOptions.find((option) => {
        choiceIndex = indexOfChoiceInOption(option, variant);
        return choiceIndex >= 0;
      });
      if (currentOption) {
        const price = Number(variant!.price!.value!);
        const servicePaymentDto: ServicePaymentDto = {
          currency: variant!.price!.currency!,
          price,
          isFree: price === 0,
          paymentType: PaymentType.ONLINE, // arbitrary, not used
          minCharge: 0,
          priceText: '',
        };
        currentOption.options[choiceIndex].price = paymentDtoMapper.priceText(
          servicePaymentDto,
        );
      }
    });
  return customOptions;
}

function indexOfChoiceInOption(
  option: CustomOption,
  variant: ServiceVariant,
): number {
  return option.options.findIndex(
    (choice) => choice.choiceId === variant.choices?.[0].custom,
  );
}

export function isPreferenceOption(dynamicPriceOptions: ServiceOption[]) {
  return (
    dynamicPriceOptions.length === 1 &&
    dynamicPriceOptions?.[0].type !== ServiceOptionType.CUSTOM
  );
}
